import * as React from "react"
import { useEffect, useState } from "react"
import moment from "moment"
import Axios from "axios"

import CsvColumnRow from "./csvColumnRow"
import Tags from "./tags"
import { DATA_IS_FOR_COMPANY, DATA_IS_FOR_CONTACT } from "./importHelprt"
import { getFullName } from "../../../services/settingsHelper"

const MapFields = ({
  isRowAfterUpload,
  csvRows,
  selectedFileName,
  tags,
  setTags,
  tempTags,
  setTempTags,
  importType,
}) => {
  const [isIndustry, setIsIndustry] = useState(false),
    [industries, setIndustries] = useState([]),
    [dataIsFor, setDataIsFor] = useState("Contacts"),
    [groupName, setGroupName] = useState(selectedFileName)
  const getIndustries = () => {
    return new Promise(async resolve => {
      await Axios.get(`${process.env.GATSBY_API_URL}/industry/list`).then(response => {
        setIndustries(response.data.data)
      })
    })
  }

  useEffect(() => {
    getIndustries().then(r => r)
  }, [])

  const handleSetAllRecordsTo = event => {
    setIsIndustry(event.target.value === "Industry")
  }

  const handleDataIsFor = event => {
    setDataIsFor(event.target.value)
  }

  return (
    <div className={`row m-b-5 rowAfterUpload${!isRowAfterUpload ? " display-none" : ""}`}>
      <div className="col-lg-12">
        <div className="tve">
          <table className="table table-bordered table-hover tblCSVMapping">
            <thead className={`ContactRow${!isRowAfterUpload ? " d-none" : ""}`}>
              <tr>
                <th scope="col" className="text-end" width="30%">
                  {selectedFileName + " Data"}
                </th>
                <th scope="col">SwiftCloud Field</th>
              </tr>
            </thead>
            <tbody>
              {csvRows.map((name, index) => {
                return <CsvColumnRow key={index} name={name} />
              })}
              <tr>
                <td className="text-end">Tag all contacts with...</td>
                <td>
                  <div className="editFeedTags">
                    <Tags tags={tags} setTags={setTags} tempTags={tempTags} setTempTags={setTempTags} />
                  </div>
                </td>
              </tr>
              {importType !== "MyContacts" && (
                <tr>
                  <td className="text-end">Optional: Set all Records to...</td>
                  <td>
                    <div className="row row-cols-lg-auto g-2 align-items-center mb-1">
                      <div className="col-12">
                        <select className="form-select setAllRecordsTo" onChange={handleSetAllRecordsTo}>
                          <option value="Ignore / No Auto-Apply">Ignore / No Auto-Apply</option>
                          <option value="Industry">Industry</option>
                        </select>
                      </div>
                      <div className={`col-12 industryOpt${!isIndustry ? " d-none" : ""}`}>
                        <select className="form-select">
                          <option>== INDUSTRY ==</option>
                          {industries.map((industry, index) => {
                            return (
                              <option key={index} value={industry.id}>
                                {industry.name}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                      {/*<div className="col-12">
                      <a href="#" className="btnAddNewGreenDashed">
                        <i className="bi bi-plus-lg" />
                      </a>
                    </div>*/}
                    </div>
                  </td>
                </tr>
              )}
              <tr className="newAudienceGroupRow">
                <td className="text-end nameThisLabel">Name this Audience / Group as...</td>
                <td>
                  <div className="editFeedTags">
                    <input
                      type="text"
                      name="group_name"
                      id="group_name"
                      className="form-control"
                      required=""
                      defaultValue={selectedFileName}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="text-end nameThisLabel">Notes about this import</td>
                <td>
                  <textarea
                    name="comments"
                    className="form-control"
                    defaultValue={`Imported By ${getFullName()} from ${selectedFileName} on ${moment().format(
                      "D MMMM YYYY @ h:mm:ss a"
                    )}`}
                    onChange={() => {}}
                  />
                </td>
              </tr>
              <tr>
                <td className="text-end">
                  <i className="fa fa-user-circle-o" /> This data is..
                </td>
                <td>
                  <div>
                    <input
                      type="radio"
                      name="data_is_for"
                      id="data_is_for_contacts"
                      className="css-checkbox"
                      value={DATA_IS_FOR_CONTACT}
                      defaultChecked
                      onChange={handleDataIsFor}
                    />
                    <label htmlFor="data_is_for_contacts" className="css-radio-label mr-2 mb-0">
                      "Cold" contacts. I cannot prove Opt-in.{" "}
                      <a href="https://swiftcrm.com/support/email" target="_blank" rel="noreferrer">
                        <i className="bi bi-question-circle-fill" />
                      </a>
                    </label>
                    <br />
                    <input
                      type="radio"
                      name="data_is_for"
                      id="data_is_for_company"
                      className="css-checkbox"
                      value={DATA_IS_FOR_COMPANY}
                      onChange={handleDataIsFor}
                    />
                    <label htmlFor="data_is_for_company" className="css-radio-label  mb-0">
                      My company newsletter. I can prove opt in.{" "}
                      <a href="https://swiftcrm.com/support/email" target="_blank" rel="noreferrer">
                        <i className="bi bi-question-circle-fill" />
                      </a>
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="text-end" />
                <td>
                  <button className="btn btn-primary btn-lg btnImport" type={"submit"}>
                    <i className="fa fa-cloud-upload" /> Import Now
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default MapFields
