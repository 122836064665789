import * as React from "react"
import queryString from "query-string"

import PrivateRoute from "../../components/privateRoute"
import ReduxWrapper from "../../redux/reduxWrapper"
import SelectFile from "../../components/Contacts/CSVImport/selectFile"

const CSVImport = props => {
  return (
    <PrivateRoute
      component={SelectFile}
      location={props.location}
      groupId={queryString.parse(props.location.search).groupId}
    />
  )
}

const WrappedPage = props => <ReduxWrapper element={<CSVImport {...props} />} />
export default WrappedPage

export const Head = () => <title>CSV Import</title>
