import * as React from "react"
import { useEffect, useState } from "react"
import { Link, navigate } from "gatsby"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

import Layout from "../../Layout/layout"
import MapFields from "./mapFields"

import "./csvImport.css"
import { DATA_IS_FOR_COMPANY, DATA_MAP_OPTIONS } from "./importHelprt"
import { CONTACT_OPT_IN_TYPE } from "../../../services/globalStaticData"
import AxiosInstance from "../../../services/axiosInstance"

const SelectFile = ({ groupId }) => {
  const [fileContent, setFileContent] = useState("")
  const [selectedFileName, setSelectedFileName] = useState("")
  const [isRowAfterUpload, setIsRowAfterUpload] = useState(false)
  const [csvRows, setCsvRows] = useState([])
  const [importType, setImportType] = useState(
    typeof groupId !== "undefined" ? "ExistingAudience" : "MyContacts"
  )
  const [groups, setGroups] = useState([]),
    [tags, setTags] = useState([]),
    [tempTags, setTempTags] = useState([]),
    [selectedGroupId, setSelectedGroupId] = useState(typeof groupId !== "undefined" ? groupId : "")

  const getContactGroups = () => {
    return new Promise(async resolve => {
      await AxiosInstance.get(`/contact/group`, {
        params: {
          page: 1,
        },
      }).then(response => {
        setGroups(response.data.items)
      })
    })
  }

  useEffect(() => {
    getContactGroups().then(r => r)
  }, [])

  const handleImportType = event => {
    setImportType(event.target.value)
  }

  const handleFileSelect = event => {
    const fileName = event.target.value

    const allowedExtensions = /(\.csv|\.xls)$/i
    if (!allowedExtensions.exec(fileName)) {
      alert("Please upload file having extensions .csv or .xls only.")
      return false
    }

    setFileContent(fileName)
    setSelectedFileName(event.target.files[0].name)
    setIsRowAfterUpload(true)
    getCsvHeader(event)
  }

  const getCsvHeader = event => {
    const reader = new FileReader()
    reader.onload = function () {
      const result = reader.result
      if (result !== "\n") {
        const data = result.split("\n")
        const headers = data[0].split(",")

        const csvRows = []
        for (const text of headers) {
          let optionText = text.replace("_", " ")
          optionText = optionText.replace("\r", "")
          csvRows.push(optionText.charAt(0) + optionText.slice(1))
        }
        setCsvRows(csvRows)
      }
    }
    reader.readAsBinaryString(event.target.files[0])
  }

  const submitImportCsvFrom = e => {
    e.preventDefault()
    const formData = new FormData(e.target)
    var data = {
      import_type: formData.get("importType"),
      csv_file: formData.get("csv_file"),
      MarketingAudienceRecommended: formData.get("MarketingAudienceRecommended"),

      // feed_tags: tempTags,
      feed_tags: [],
      group_name: formData.get("group_name"),
      comments: formData.get("comments"),
      dataIsFor: formData.get("data_is_for"),
    }

    if (formData.get("importType") === "ExistingAudience") {
      if (
        formData.get("existing_contact_group_id") === "" ||
        formData.get("existing_contact_group_id") === null ||
        formData.get("existing_contact_group_id") === "== AUDIENCE / GROUP =="
      ) {
        alert("Please select existing contact group 1st")
        return false
      }

      data.existing_contact_group_id = formData.get("existing_contact_group_id")
    }

    let csvFields = []
    var optInColumn = false
    {
      DATA_MAP_OPTIONS.map((fieldOption, index) => {
        if (formData.get(fieldOption.type)) {
          csvFields = [...csvFields, { type: fieldOption.type, columnName: formData.get(fieldOption.type) }]
          if (!optInColumn) {
            optInColumn = fieldOption.type === CONTACT_OPT_IN_TYPE.type
          }
        }
      })
    }
    setTimeout(() => {}, 300)
    data["csvColumns"] = csvFields

    if (data.dataIsFor === DATA_IS_FOR_COMPANY && !optInColumn) {
      alert("Please add Opt-In Ip")
      return false
    }
    return new Promise(async () => {
      await AxiosInstance.post(`/contact/group/import-spread-sheet`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (response) {
          if (response.status === 201) {
            navigate("/contacts/groups/detail/" + response.data.group)
            return false
          }
        })
        .catch(function (error) {})
    })
  }

  return (
    <Layout>
      <form onSubmit={submitImportCsvFrom}>
        <div id="right-section" className="h-100 csvImport">
          <div className="row g-0">
            <div className="col" id="page-urls">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row g-0">
                    <div className="col-sm-12">
                      <h1>
                        Import from Spreadsheet{" "}
                        <a
                          href="https://swiftcrm.com/support/csv-import"
                          target="_blank"
                          rel="noreferrer"
                          className="fs-4"
                        >
                          <i className="bi bi-question-circle-fill" />
                        </a>
                      </h1>
                    </div>
                    <div className="col-sm-12 mb-3">
                      <ul className="nav nav-wizard">
                        <li className="SaleStatus_Pending statusAddData" data-step="3">
                          <a href="#" data-bs-toggle="tooltip" data-bs-title="Select File">
                            <span> 1. Select File </span>
                          </a>
                        </li>
                        <li
                          className={`${isRowAfterUpload ? "SaleStatus_Pending" : "SaleStatus_NotStarted"}`}
                          data-step="1"
                        >
                          <a href="#" data-bs-toggle="tooltip" data-bs-title="Map Fields">
                            <span>2. Map Fields</span>
                          </a>
                        </li>
                        <li className="SaleStatus_NotStarted" data-step="2">
                          <a href="#" data-bs-toggle="tooltip" data-bs-title="Process Files">
                            <span>3. Process Files</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 text-left importTypeContainer">
                      <div className="row row-cols-lg-auto g-2 align-items-center mb-1">
                        <div className="col-12">
                          <strong>Import</strong>
                        </div>
                        <div className="col-12">
                          <select className="form-select import_type">
                            <option value="CSV">CSV</option>
                          </select>
                        </div>
                        <div className="col-12">
                          <strong>as</strong>
                        </div>
                      </div>

                      <div className="mb-3">
                        <input
                          type="radio"
                          name="importType"
                          id="importTypeMyContacts"
                          className="css-checkbox"
                          value="MyContacts"
                          defaultChecked={typeof groupId === "undefined"}
                          onChange={handleImportType}
                        />
                        <label htmlFor="importTypeMyContacts" className="css-radio-label me-2">
                          <strong>My Contacts</strong>
                          <br />
                          Create New Group / Audience + Show in my main contacts list{" "}
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip className="ms-1 d-inline-block">
                                Typically only for people you really know, or existing clients.
                              </Tooltip>
                            }
                          >
                            <i className="bi bi-question-circle-fill" />
                          </OverlayTrigger>
                        </label>
                      </div>
                      <div className="mb-3">
                        <input
                          type="radio"
                          name="importType"
                          id="importTypeMarketingAudience"
                          className="css-checkbox"
                          value="MarketingAudience"
                          onChange={handleImportType}
                        />
                        <label htmlFor="importTypeMarketingAudience" className="css-radio-label me-2">
                          <strong>Marketing Audience / Group (Prospects)</strong>
                          <br />
                          Create New Group / Audience; Hide from Main Contacts{" "}
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip className="ms-1 d-inline-block">
                                Typically used for marketing prospects only. Will not show in your main
                                contacts list so you don't clutter up your primary contacts. You can access
                                them anytime through the new group / audience. If they become clients /
                                active, you can (and should!) move them anytime to your main contacts.
                              </Tooltip>
                            }
                          >
                            <i className="bi bi-question-circle-fill" />
                          </OverlayTrigger>
                        </label>
                      </div>

                      <div className="mb-3">
                        <div className="row row-cols-lg-auto g-1 align-items-center">
                          <div className="col-12">
                            <input
                              type="radio"
                              name="importType"
                              id="importTypeExistingGroup"
                              className="css-checkbox"
                              value="ExistingAudience"
                              onChange={handleImportType}
                              defaultChecked={typeof groupId !== "undefined"}
                            />
                            <label htmlFor="importTypeExistingGroup" className="css-radio-label me-2">
                              Contacts: Add to Existing Audience / Group
                            </label>
                          </div>
                          {importType === "ExistingAudience" && (
                            <>
                              <div className={`col-12 importAsDropdown addToExistingAudience`}>
                                <select
                                  className="form-select"
                                  name="existing_contact_group_id"
                                  value={selectedGroupId}
                                  onChange={e => {
                                    setSelectedGroupId(e.target.value)
                                  }}
                                >
                                  <option value="">== AUDIENCE / GROUP ==</option>
                                  {groups.map((group, index) => {
                                    return (
                                      <option value={group.id} key={index}>
                                        {group.name}
                                      </option>
                                    )
                                  })}
                                </select>
                              </div>
                              <div className={`col-12 importAsDropdown addToExistingAudience`}>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip className="ms-1 d-inline-block">Add Audience / Group</Tooltip>
                                  }
                                >
                                  <Link
                                    to="/contacts/add?action=add-audience"
                                    className="btnAddNewGreenDashed"
                                  >
                                    <i className="bi bi-plus" />
                                  </Link>
                                </OverlayTrigger>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="row row-cols-lg-auto g-1 align-items-center">
                          <div className="col-12">
                            <label htmlFor="firstRowOption" className="form-label me-2 mt-2">
                              1st Row is
                            </label>
                          </div>
                          <div className="col-12">
                            <select name="firstRowOption" id="firstRowOption" className="form-select">
                              <option>Header (Ignore it)</option>
                              <option>Data (Import it)</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="mt-1 MarketingAudienceRecommendedContainer">
                        <input
                          type="checkbox"
                          name="MarketingAudienceRecommended"
                          id="MarketingAudienceRecommended1"
                          className="css-checkbox"
                          value="ValidateEmails"
                          defaultChecked
                        />
                        <label htmlFor="MarketingAudienceRecommended1" className="css-checkbox-label me-2">
                          <i className="bi bi-check2" /> Recommended: Validate Emails{" "}
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip className="ms-1 d-inline-block">
                                Credits Required: Will clean the list, check for non-deliverable, honeypots,
                                etc.
                              </Tooltip>
                            }
                          >
                            <i className="bi bi-question-circle-fill" />
                          </OverlayTrigger>
                        </label>
                      </div>
                      <div className="mt-1 MarketingAudienceRecommendedContainer">
                        <input
                          type="checkbox"
                          name="MarketingAudienceRecommended"
                          id="MarketingAudienceRecommended2"
                          className="css-checkbox"
                          value="ScrubPhoneNumbers"
                          defaultChecked
                        />
                        <label htmlFor="MarketingAudienceRecommended2" className="css-checkbox-label me-2">
                          <i className="bi bi-shield-fill" /> Recommended: Scrub Phone Numbers{" "}
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip className="ms-1 d-inline-block">
                                Credits Required: Will scrub against Do Not Call list
                              </Tooltip>
                            }
                          >
                            <i className="bi bi-question-circle-fill" />
                          </OverlayTrigger>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-lg-12 text-left">
                      <div className={`upload-btn-wrapper${isRowAfterUpload ? " display-none" : ""}`}>
                        <button
                          type="button"
                          className={`btn btn-primary btn-lg btnSelectCSV${
                            isRowAfterUpload ? " display-none" : ""
                          }`}
                          disabled={isRowAfterUpload}
                        >
                          <i className="bi bi-cloud-arrow-up" /> Select Spreadsheet{" "}
                          <i className="bi bi-table" />
                        </button>
                        <input
                          type="file"
                          name="csv_file"
                          id="csv_file"
                          className="csv_file"
                          onChange={handleFileSelect}
                        />
                        <input type="hidden" name="file-return" id="file-return" className="file-return" />
                      </div>
                    </div>
                  </div>

                  <MapFields
                    csvRows={csvRows}
                    tags={tags}
                    setTags={setTags}
                    tempTags={tempTags}
                    setTempTags={setTempTags}
                    isRowAfterUpload={isRowAfterUpload}
                    selectedFileName={selectedFileName}
                    importType={importType}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Layout>
  )
}

export default SelectFile
