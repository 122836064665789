import * as React from "react"
import { useEffect, useState } from "react"
import { createTagString } from "../../../services/tagsHelper"
import Axios from "axios"
import { getAccessToken } from "../../../services/auth"

import "../../../css/bootstrap-tagsinput.css"

const Tags = ({ tags, setTags, tempTags, setTempTags }) => {
  const [uiBlockClasses, setUiBlockClasses] = useState("CD_PrivateTag_Container d-none"),
    [editBlockClasses, setEditBlockClasses] = useState("CD_PrivateTag_Editor position-relative"),
    [plainTags, setPlainTags] = useState(createTagString(tags)),
    [newTag, setNewTag] = useState(""),
    [addTag, setAddTag] = useState(false),
    [updateTags, setUpdateTags] = useState(false),
    [restoreTags, setRestoreTags] = useState(false),
    [removeTagIndex, setRemoveTagIndex] = useState(null)
  const showEditTagBlock = () => {
    setUiBlockClasses("CD_PrivateTag_Container d-none")
    setEditBlockClasses("CD_PrivateTag_Editor position-relative")
  }

  useEffect(() => {
    if (addTag) {
      setAddTag(false)
      setTempTags([
        ...tempTags,
        {
          tag: newTag,
          score: 0,
        },
      ])
      setNewTag("")
    }

    if (removeTagIndex !== null) {
      setRemoveTagIndex(null)
      let tempTag = []

      tempTags.map((tagArray, arrayIndex) => {
        if (parseInt(removeTagIndex) !== arrayIndex) {
          tempTag = [...tempTag, tagArray]
        }

        if (arrayIndex === tempTags.length - 1) {
          setTempTags([...tempTag])
        }
      })
    }

    if (updateTags) {
      setUpdateTags(false)
      setTags([...tempTags])
    }

    if (restoreTags) {
      setRestoreTags(false)
      setTempTags([...tags])
    }
  })

  return (
    <React.Fragment>
      <div className={uiBlockClasses}>
        <div className="SwiftCloudTableTags">
          <div className="SwiftCloudTableTags">
            <ul>
              {tempTags.length > 0 &&
                tempTags.map(tagDetail => (
                  <li key={tagDetail.tag}>
                    <a href="#" data-tagname="Designer">
                      {tagDetail.tag}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
      <div className={editBlockClasses}>
        <div className="bootstrap-tagsinput">
          {tempTags.length > 0 &&
            tempTags.map((tagDetail, tagIndex) => (
              <span className="tag label label-info" key={tagDetail.tag}>
                {tagDetail.tag}
                <span data-role="remove" onClick={() => setRemoveTagIndex(`${tagIndex}`)} />
                <span data-role="drag" />
              </span>
            ))}
          <input
            type="text"
            placeholder="Enter a #tag then click Tab"
            size="27"
            onKeyDown={e => {
              if (e.code === "Tab") {
                setAddTag(true)
              }
            }}
            value={newTag}
            onChange={e => {
              setNewTag(e.target.value)
            }}
          />
        </div>
        <input type="text" name="feed_tags" className="form-control d-none" />
      </div>
    </React.Fragment>
  )
}

export default Tags
