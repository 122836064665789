import * as React from "react"
import { useEffect, useState } from "react"
import Axios from "axios"
import moment from "moment"

import { getAccessToken } from "../../../services/auth"
import Dropdown from "react-bootstrap/Dropdown"
import {
  CONTACT_META_DATE_FIELDS,
  CONTACT_META_FIELD_OPTIONS,
  DEFAULT_CONTACT_PHONE_OPTION,
} from "../../../services/globalStaticData"
import { DATA_MAP_OPTIONS, IGNORE_FIELD } from "./importHelprt"

const CsvColumnRow = ({ name, OptInIP, dataIsFor }) => {
  const [dataRowType, setDataRowType] = useState(IGNORE_FIELD.type),
    [dataRowIcon, setDataRowIcon] = useState(IGNORE_FIELD.icon),
    [dataRowLabel, setDataRowLabel] = useState(IGNORE_FIELD.label)
  const rowClass = OptInIP ? `ContactRow OptInIPRow${dataIsFor === "Contacts" ? " d-none" : ""}` : ""

  return (
    <tr className={rowClass}>
      <td className="text-end">{name}</td>
      <td>
        <div className={`dropdown SwiftCloudField_${name}`}>
          <input type="hidden" name={dataRowType} value={name} />
          <Dropdown>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
              <i className={dataRowIcon} />
              &nbsp;{dataRowLabel}
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ maxHeight: "250px", overflow: "auto" }}>
              {DATA_MAP_OPTIONS.map((fieldOption, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    onClick={e => {
                      setDataRowIcon(fieldOption.icon)
                      setDataRowLabel(fieldOption.label)
                      setDataRowType(fieldOption.type)
                    }}
                    disabled={fieldOption.type === ""}
                  >
                    {fieldOption.type !== "" && (
                      <React.Fragment>
                        <i className={fieldOption.icon} />
                        &nbsp;&nbsp;
                      </React.Fragment>
                    )}
                    {fieldOption.label}
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </td>
    </tr>
  )
}

export default CsvColumnRow
