import { CONTACT_META_FIELD_OPTIONS } from "../../../services/globalStaticData"

export const DATA_IS_FOR_CONTACT = "contact"
export const DATA_IS_FOR_COMPANY = "company"

export const IGNORE_FIELD = {
  label: "Ignore (Do Not Import)",
  type: "",
  icon: "bi bi-slash-circle",
  placeHolder: "",
}

export const DATA_MAP_OPTIONS = [
  IGNORE_FIELD,
  {
    label: "Add to Notes / History",
    type: "add_to_history",
    icon: "bi bi-arrow-counterclockwise",
    placeHolder: "",
  },
  {
    label: "Tags",
    type: "tags",
    icon: "bi bi-tag",
    placeHolder: "",
  },
  {
    label: "== NAMES ==",
    type: "",
    icon: "",
    contactApi: "",
    placeHolder: "",
  },
  {
    label: "Both Name",
    type: "both_name",
    icon: "bi bi-person-fill",
    placeHolder: "",
  },
  {
    label: "First Name",
    type: "first_name",
    icon: "bi bi-person-fill",
    placeHolder: "",
  },
  {
    label: "Last Name",
    type: "last_name",
    icon: "bi bi-person-fill",
    placeHolder: "",
  },
  ...CONTACT_META_FIELD_OPTIONS,
]
